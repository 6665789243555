<template>
  <div class="all_main_wrap">
    <!-- 动态统计报表详情 -->
    <HeaderBox>
      <template #title> 报表详情 </template>
    </HeaderBox>

    <!-- 筛选条件 -->
    <div class="selects">
      <template v-for="item in queryColumnList">
        <!-- 输入框 -->
        <a-form-item v-if="item.type == 1" :label="item.showName+'：'" style="display: inline-flex; margin: 0">
          <a-input
            v-model="searchObj[item.name]"
            :placeholder="'请输入' + item.showName"
          />
        </a-form-item>

        <!-- 数字输入框 -->
        <a-form-item v-if="item.type == 2" :label="item.showName+'：'" style="display: inline-flex; margin: 0">
          <a-input-number id="inputNumber" :placeholder="'请输入' + item.showName" v-model="searchObj[item.name]" />
        </a-form-item>

        <!-- 时间选择器 -->
        <a-form-item v-if="item.type == 3" :label="item.showName+'：'" style="display: inline-flex; margin: 0">
          <a-date-picker valueFormat="YYYY-MM-DD" :placeholder="'请选择' + item.showName" v-model="searchObj[item.name]" />
        </a-form-item>

        <!-- 时间选择器精确到秒 -->
        <a-form-item v-if="item.type == 6" :label="item.showName+'：'" style="display: inline-flex; margin: 0">
          <a-date-picker showTime valueFormat="YYYY-MM-DD HH:mm:ss" :placeholder="'请选择' + item.showName" v-model="searchObj[item.name]" />
        </a-form-item>

        <!-- 时间选择器精确到年 -->
        <a-form-item v-if="item.type == 7" :label="item.showName+'：'" style="display: inline-flex; margin: 0">
          <a-date-picker
            mode="year"
            format="YYYY"
            valueFormat="YYYY"
            :open="isOpen"
            @focus="changeopen"
            @panelChange="handlePanelChange1($event,item.name)"
            :placeholder="'请选择' + item.showName"
            v-model="searchObj[item.name]" />
        </a-form-item>

        <!-- 日期时间区间选择器 -->
        <a-form-item v-if="item.type == 8" :label="item.showName+'：'" style="display: inline-flex; margin: 0">
          <a-range-picker
            :show-time="{ format: 'HH:mm:ss' }"
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            :placeholder="['开始时间', '结束时间']"
            v-model="searchObj[item.name]"
          />
        </a-form-item>

        <!-- 时间区间选择器 -->
        <a-form-item v-if="item.type == 4" :label="item.showName+'：'" style="display: inline-flex; margin: 0">
          <a-range-picker
            valueFormat="YYYY-MM-DD"
            v-model="searchObj[item.name]"
          ></a-range-picker>
        </a-form-item>

        <!-- 下拉框 -->
        <a-form-item v-if="item.type == 5" :label="item.showName+'：'" style="display: inline-flex; margin: 0">
          <a-select
            mode="multiple"
            :placeholder="'请选择' + item.showName"
            v-model="searchObj[item.name]"
            showSearch
            :filter-option="filterOption"
          >
            <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
            <a-select-option :value="ite.id" v-for="ite in item.options" :key="ite.id"> {{ite.name}} </a-select-option>
          </a-select>
        </a-form-item>
      </template>
      <a-button type="primary" :loading="loading" @click="search">查询</a-button>
      <a-button class="btn" @click="exportFile" v-hasPermi="['exp-' + searchObj.reportCode]">导出</a-button>
    </div>

    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item,index) => index"
        :columns="columns"
        :data-source="tableData"
        @change="onPage"
        :scroll="{ x: true }"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
      </a-table>
    </div>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  data() {
    return {
      // 查询字段
      searchObj: {},
      tableData: [], // 表格数据
      columns: [],

      isOpen: false, // 年份日期选择器改造
      resultList: [],
      queryColumnList: [],
      loading: false,
      total: 0,
      pageNumber: 1,
      pageSize: 10
    }
  },
  created() {
    this.searchObj.reportCode = this.$route.query.reportCode;
    this.getDetail(this.$route.query.reportCode);
  },
  methods: {
    // 下拉搜索搜索
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    // 获取详情信息
    getDetail(reportCode) {
      this.$ajax({
        url: '/hxclass-management/report-config/build',
        params: {
          reportCode: 'que-' + reportCode
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.queryColumnList = res.data.queryColumnList;
          this.resultList = res.data.resultList;
          this.initPage(); // 初始化
        }
      })
    },

    // 初始化
    initPage() {
      // 筛选项处理
      this.queryColumnList.map(item=>{
        let value = item.defaultValue;
        if(item.type == 5 && !value) {
          this.$set(this.searchObj,item.name,undefined);
        } else if(item.type == 5 && value) { // 处理下拉框数据
          let arr = value.split(',').map(item=>{
            return Number(item)
          })
          this.$set(this.searchObj,item.name,arr);
        } else if((item.type == 4 || item.type == 8) && value) { // 处理时间区间数据
          this.$set(this.searchObj,item.name,value.split('&'));
        } else {
          this.$set(this.searchObj,item.name,value);
        }        
      })
      // 表头处理
      this.resultList.map(item=>{
        this.columns.push({
          title: item,
          align: "center",
          dataIndex: item,
          // width: '200px'
        })
      })

      // 获取列表
      this.getList();
    },

    // 年份选择器改造
    handlePanelChange1(value, name) {
      this.searchObj[name] = value.format("YYYY");
      this.isOpen = false
    },
    changeopen(){
      this.isOpen = true;
    },

    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getList();
    },

    search() {
      this.pageNumber = 1;
      this.getList();
    },
    getList() {
      if(this.loading) return
      // this.loading = true;
      let params = JSON.parse(JSON.stringify(this.searchObj));
      params.$pageNum = this.pageNumber;
      params.$pageSize = this.pageSize;
      params.reportCode = 'que-' + params.reportCode;
      
      // 循环处理数组数据  下拉框改为,隔开  时间区间改为&隔开
      for(let value in params){
        console.log(Array.isArray(params[value]))
        if(!params[value] || !Array.isArray(params[value])){
          continue;
        }
        this.queryColumnList.some(item => {
          if(item.name == value && item.type == 5) {
            params[value] = params[value].join(',')
          }else if(item.name == value && (item.type == 4 || item.type == 8)) {
            if(item.type == 4 && params[value][0]) {
              params[value][0] = params[value][0] + ' 00:00:00';
              params[value][1] = params[value][1] + ' 23:59:59';
            }
            params[value] = params[value].join('&')
          }
        })
      }
      this.$ajax({
        url: '/hxclass-management/report-config/query-data',
        method: 'POST',
        params
      }).then(res=>{
        this.loading = false;
        if(res.code == 200 && res.success) {
          this.total = res.data.total;
          let data = [];
          res.data.records.map(item=>{
            let arr = item.split('#');
            let obj = {};
            this.resultList.map((item,index) => {
              obj[item] = arr[index];
            })
            data.push(obj);
          })
          this.tableData = data;
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 导出
    exportFile() {
      let params = JSON.parse(JSON.stringify(this.searchObj));
      params.reportCode = 'exp-' + params.reportCode;
      // 循环处理数组数据  下拉框改为,隔开  时间区间改为&隔开
      for(let value in params){
        console.log(Array.isArray(params[value]))
        if(!params[value] || !Array.isArray(params[value])){
          continue;
        }
        this.queryColumnList.some(item => {
          if(item.name == value && item.type == 5) {
            params[value] = params[value].join(',')
          }else if(item.name == value && (item.type == 4 || item.type == 8)) {
            if(item.type == 4 && params[value][0]) {
              params[value][0] = params[value][0] + ' 00:00:00';
              params[value][1] = params[value][1] + ' 23:59:59';
            }
            params[value] = params[value].join('&')
          }
        })
      }

      window.open(
        this.$config.target + 
        // 'http://192.168.110.25:8083' +
        "/hxclass-management/report-config/export?params=" + encodeURIComponent(JSON.stringify(params))
      );
    }
  }
}
</script>

<style scoped lang="less">
  .selects{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    .ant-row{
      flex-basis: 300px;
      display: flex;
      /deep/.ant-form-item-control-wrapper{
        flex: 1;
        .ant-input,
        .ant-input-number,
        .ant-select{
          width: 100%;
        }
      }
    }
  }
  /deep/td,
  /deep/.ant-table-column-title{
    white-space: nowrap;
  }
</style>